@charset "utf-8";
/* font-family: 'PT Sans', sans-serif; */
@import url("https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap");
@import url("bulma/css/bulma.css");
@import url("material-design-icons/iconfont/material-icons.css");
@import url("../fonts/stylesheet.css");

html {
  /* background-color: #212121; */
  touch-action: pan-down;
}

:root {
  --purple: #8656ab;
  --green: #60ccb8;
  --yellow: #ffbc37;
  --red: #e62c59;
  --blue: #2f9ac7;
  --dark-blue: #323593;
  --gray: #869290;
}

.purple {
  background-color: var(--purple);
  border-color: var(--purple) !important;
}

.purple_border {
  background: transparent;
  border-color: var(--purple) !important;
  color: var(--purple) !important;
}

.green {
  background-color: var(--green);
  border-color: var(--green) !important;
}

.green_border {
  background: transparent;
  border-color: var(--green) !important;
  color: var(--green) !important;
}

.yellow {
  background-color: var(--yellow);
  border-color: var(--yellow) !important;
}

.yellow_border {
  background: transparent;
  border-color: var(--yellow) !important;
  color: var(--yellow) !important;
}

.red {
  background-color: var(--red);
  border-color: var(--red) !important;
}

.red_border {
  background: transparent;
  border-color: var(--red) !important;
  color: var(--red) !important;
}

.blue {
  background-color: var(--blue);
  border-color: var(--blue) !important;
}

.blue_border {
  background: transparent;
  border-color: var(--blue) !important;
  color: var(--blue) !important;
}
.gray {
  background-color: var(--gray);
  border-color: var(--gray) !important;
}
.gray_border {
  background: transparent;
  border-color: var(--gray) !important;
  color: var(--gray) !important;
}

li {
  list-style: none;
}
body {
  /* font-family: "PT Sans", sans-serif; */
  font-family: "Myriad Pro";
  background: #f5f6f7;
}
body.modal-open {
  overflow: hidden;
  height: 100vh;
}
p {
  color: #858585;
  font-size: 14px;
  line-height: 1.5;
}
* {
  box-sizing: border-box;
}
/*common*/
.btn.blue-btn {
  background: #00022e;
  color: #fff;
  padding: 5px 35px;
}
input,
button,
select,
input:focus,
button:focus,
select:focus,
input:active,
button:active,
select:active,
input.form-control,
input.form-control:focus,
input.form-control:active,
textarea.form-control,
textarea.form-control:focus,
textarea.form-control:active {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: 0;
  box-shadow: none;
}
::placeholder {
  color: #c4c4c4;
  opacity: 1; /* Firefox */
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #c4c4c4;
}
::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #c4c4c4;
}
img {
  max-width: 100%;
}

.wrapper {
  max-width: 767px;
  margin: auto;
  position: relative;
}
/*home*/
header {
  padding: 20px 0 0;
  overflow: hidden;
  /* -webkit-transition: 0.3s all;
  transition: 0.3s all; */
}

.px_15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

header.verify_header {
  padding: 20px 0 10px;
}
header.verify_header .left_col {
  display: flex;
  align-items: center;
}

header.verify_header .notify .notification {
  margin-bottom: 0;
}

.powered_logo {
  width: 54px;
  display: inline-block;
  margin: 0 0 5px 5px;
}
.circle-img {
  padding: 0 0;
  align-self: center;
}
.disable .circle-img,
.disable .naming-sec {
  display: none;
}
.profile-row.disable {
  justify-content: flex-end;
}
.circle-img-lg {
  background: url(../Assets/images/image_circle.svg) no-repeat center center /
    100% auto;
  height: 105px;
  padding: 0;
  border-radius: 50%;
}
.circle-img-lg figure {
  display: flex;
  margin: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.naming-sec h3 {
  font-size: 25px;
  color: #3e3e3e;
}
.circle-img figure {
  border: 1px solid #bbb;
  border-radius: 50%;
  margin: 0;
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  overflow: hidden;
}
.naming-sec h4 {
  font-weight: bold;
  font-size: 16px;
  color: #3d3e3d;
  margin: 0;
}
header .container {
  padding: 0 15px;
}
header.hideClass {
  display: none;
}

.naming-sec p {
  margin: 0;
  color: #3d3e3d;
}
.search-bar.input-text {
  background: #fff url(../Assets/images/search_icon.svg) no-repeat 15px 50% /
    18px auto;
  border: 1px solid #bababc;
  margin: 8px auto;
  border-radius: 5px;
  height: 45px;
  padding: 0 20px 0 45px;
  width: 100%;
  font-size: 14px;
  color: #3d3d3d;
}
.notification {
  display: block;
  width: 30px;
  height: 30px;
  background: url(../Assets/images/notification_icon.svg) no-repeat center
    center;
  position: relative;
  padding: 0;
  margin: 0;
  margin-right: 20px;
  margin-bottom: 5px !important;
}
.profile {
  display: block;
  width: 30px;
  height: 30px;
  background: #1ebdc3 url(../Assets/images/user.svg) no-repeat center center /
    18px auto;
  border-radius: 50%;
}
.notify {
  display: flex;
  justify-content: flex-end;
  /* justify-content: space-between; */
}
.counts {
  position: absolute;
  left: -16px;
  top: 14px;
  background: #d65b4c;
  color: #fff;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 1.5;
  border: 2px solid #f5f6f7;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.link-box {
  padding: 0 5px 15px;
  position: relative;
}
.link-box.active:after {
  content: "";
  display: inline-block;
  width: calc(100% - 10px);
  height: 5px;
  background: #ccc;
  bottom: 4px;
  position: absolute; /*max-width: 85px;*/
  left: 0;
  right: 0;
  margin: auto;
}
.link-box a,
.link-box .box {
  display: flex;
  height: 85px;
  width: 85px;
  background: #fff;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  text-transform: uppercase;
  padding: 0;
  border-radius: 5px;
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  margin: auto;
  /* -webkit-transition: 0.1s all;
  transition: 0.1s all; */
  text-align: center;
}
.link-box .blue {
  background: #2793c2;
}
.link-box .purple {
  background: #9359a5;
}
.link-box .yellow {
  background: #f6bf1e;
}
.link-box .green {
  background: #63c6b4;
}
.link-box .pink {
  background: #d92a93;
}
.menu-row {
  flex-wrap: nowrap;
  padding: 0 10px;
  overflow: auto;
}
.scroll_active .menu-row {
  margin-left: 10px !important;
}
.menu-box {
  margin: 0;
  /* background: rgb(255, 0, 0); */
  background: #fff;
  padding: 15px 20px;
  position: relative;
}

.menu-box.disabled {
  opacity: 0.2;
}

.menu-box a {
  text-decoration: none;
}
.menu-box::after {
  content: "";
  border-bottom: 1px solid #e8e8e8;
  width: calc(100% - 32px);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.menu-box:last-child::after {
  display: none;
}
.menu-img {
  padding: 0;
  margin: 0;
}

.menu-img img {
  height: 80px;
  width: 100%;
}
.menu-content {
  padding-right: 0;
}
.menu-content h4 {
  font-weight: bold;
  font-size: 18px;
  color: #585555;
  /* margin: 0 auto 5px; */
  margin: 16px auto 5px;
}
.txt-green {
  color: #1cbec3;
}
.menu-content p {
  color: #5a5655;
  font-size: 15px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  margin-bottom: 5px;
  /* font-style: italic; */
}
.menu-content p.pricing {
  font-size: 18px;
}
.menu-box.active::before {
  content: "";
  width: 8px;
  height: calc(100% - 20px);
  position: absolute;
  background: #1ebdc3;
  left: 0;
  top: 10px;
}
.menu-sections .title {
  font-weight: bold;
  font-size: 22px;
  padding: 15px 15px;
  margin: 0;
}
.menu-sections {
  height: calc(100vh - 525px);
  overflow-y: auto;
  /* -webkit-transition: 0.3s all;
  transition: 0.3s all; */
}
.bottom-sec {
  color: #fff;
  padding: 15px 0px;
}
.bottom-sec.flex_btns {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.bottom-sec.flex_btns .adding-items {
  max-width: 100%;
  flex: 0 0 100%;
}

.bottom-sec.flex_btns .btns {
  flex: 0 0 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
}

.bottom-sec.flex_btns .btns.duo {
  padding: 0 10px;
}

.bottom-sec.flex_btns .btns button {
  width: 100%;
  margin: 15px auto 0;
}

.bottom-sec.flex_btns .btns .back_btn {
  max-width: 100px;
}

.bottom-sec.flex_btns .btns .add_btn {
  max-width: calc(100% - 120px);
}

.bottom-sec .green-sec {
  margin: 0;
  display: flex;
  justify-content: space-between;
  background: #1ebdc3;
  padding: 6px 5px;
  border-radius: 5px;
  align-items: center;
  color: #fff;
  cursor: pointer;
}

.bottom-sec .green-sec strong {
  color: #fff;
  font-weight: 600;
}

.items-count {
  display: inline-block;
  width: 28px;
  height: 28px;
  background: #188e8e;
  text-align: center;
  border-radius: 5px;
  font-weight: bold;
  font-size: 20px;
}
.green-sec .total-price strong {
  font-size: 23px;
  display: block;
  margin-bottom: -8px;
}
.green-sec .total-price strong small {
  font-weight: bold;
  font-size: 70%;
}
.total-price {
  text-transform: uppercase;
  text-align: center;
}
.green-sec strong {
  font-size: 20px;
}
.welcome-sec {
  align-items: baseline;
  margin-top: -35px;
  align-items: flex-end;
}
.welcome-sec p {
  color: #858585;
}
.ratings-sec p {
  margin: 0 auto 8px;
}
.ratings-sec {
  width: 100%;
  padding: 10px 0;
}
.ratings-sec .ratings {
  border: 1px solid #bababc;
  padding: 6px 40px;
  border-radius: 40px;
  background: #fff;
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
}
.ratings .star {
  display: inline-block;
  width: 35px;
  height: 35px;
  background: url(../Assets/images/star.svg) no-repeat center center;
}

/*Add items sec*/
.image-sec figure {
  margin: 0;
}
.item-details {
  background: #f5f6f8;
  margin-top: -63px;
  position: relative;
  border-radius: 30px 30px 0 0;
  padding: 0px 0;
  overflow: hidden;
}
.product-details {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  background: #fff;
  padding: 22px 20px 18px;
  align-items: center;
}
.item-details .selectors .options {
  padding: 11px 0;
}
.item-details .selectors h5.sm-title.pl-1 {
  padding-bottom: 4px;
  padding-left: 0 !important;
}
.product-details p {
  margin: 10px auto 0;
}
.product-details .name,
.product-details .price {
  padding: 0;
  margin: 0;
}
.product-details .price {
  text-align: right;
  font-size: 18px;
}
.sm-title {
  margin: 0;
  padding: 15px 20px;
  font-weight: bold;
  color: #5b5b5d;
}
.product-details .name {
  font-weight: bold;
  font-size: 22px;
  color: #3d3d3d;
}
.options label:before {
  content: "";
  display: inline-block;
  width: 25px;
  height: 25px;
  background: #fff;
  border-radius: 5px;
  border: 2px solid #bababc;
  margin-right: 15px;
}
.options input.check:checked + label:before {
  background: #ccc;
}
.options label {
  display: flex;
  align-items: center;
  color: #595556;
  margin: 0;
}
input.check {
  display: none;
}
.selectors {
  padding: 20px 20px;
  background: #fff;
}
.options {
  display: flex;
  padding: 15px 0;
  justify-content: space-between;
}
.price-sm {
  color: #595556;
}
.items-sec {
  display: flex;
  align-items: center;
}
.remove-btn,
.add-btn {
  display: inline-block;
  width: 40px;
  height: 40px;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #bababa;
}
.items-num {
  border: 0;
  appearance: none;
  -webkit-appearance: none;
  width: 60px;
  height: 50px;
  text-align: center;
  font-size: 26px;
  font-weight: bold;
  color: #535353;
}
.adding-items {
  display: flex;
  justify-content: space-between;
  padding: 8px 20px;
  background: #fff;
  margin: 10px auto 0;
}
.price-sec p {
  margin: -5px 0 0 0;
  text-transform: uppercase;
}
.price-sec strong {
  font-size: 22px;
  color: #1ebdc3;
}
.price-sec {
  text-align: right;
}
.add-btn {
  background: url(../Assets/images/plus.svg) no-repeat center center / 20px;
}
.remove-btn {
  background: url(../Assets/images/minus.svg) no-repeat center center / 20px;
}
.green-btn {
  background: #1ebdc3;
  border: 2px solid transparent;
  color: #fff;
  border-radius: 7px;
  height: 65px;
  width: 100%;
  font-size: 20px;
  font-weight: bold;
}
.bottom-sec .green-btn {
  max-width: calc(100% - 30px);
  margin: 15px auto 0;
  display: block;
}

.bottom-sec .green-sec {
  max-width: calc(100% - 30px);
  margin: 0px auto;
}
.green-border-btn {
  background: transparent;
  border: 2px solid #1ebdc3;
  color: #1ebdc3;
  height: 65px;
  font-size: 20px;
  font-weight: bold;
  border-radius: 7px;
}

.bottom-sec.flex_btns .green-border-btn {
  margin-right: 20px;
  padding: 0 20px;
}

.cross-btn,
.cross-icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  position: absolute;
  z-index: 1;
  background: url(../Assets/images/cross_wht.svg) no-repeat center center;
  left: 12px;
  top: 12px;
  cursor: pointer;
  /* box-shadow: 0 0 5px rgb(0 0 0 / 12%); */
  border-radius: 50%;
}

/*cart page*/
.hotel_name {
  justify-content: center;
  /* margin: 20px auto 0; */
  align-items: center;
}
.hotel_name .naming-sec p {
  color: #858585;
}
.back,
a.back,
a.back:hover {
  padding-left: 20px;
  color: #1cbec3;
  font-weight: bold;
  background: url(../Assets/images/left_arrow_green.svg) no-repeat center left /
    8px;
  text-decoration: none;
  font-size: 17px;
}
h5.green-title {
  color: #1cbec3;
  font-weight: bold;
  font-size: 17px;
  margin: 0;
}
.cart-sec-ms .menu-content h4 {
  font-size: 16px;
  margin-bottom: 0;
}
.other-options {
  padding: 0 0 0 25px;
  line-height: normal;
  margin: 0;
}
.pricing-lg {
  font-size: 18px;
  margin: 0;
}
.cart-sec-ms .menu-box {
  align-items: center;
  padding: 20px 15px;
}

.cart-sec-ms .menu-box .menu-img {
  padding: 0px 0px;
  overflow: hidden;
  width: 70px;
  height: 70px;
  margin-right: 10px;
}
.cart-sec-ms .menu-box img {
  /* height: 80px;
  width: 100%; */
}
.cart-sec-ms .menu-content h4 {
  margin: 0;
}
.cart-sec-ms .menu-box.added:before {
  content: "";
  width: 7px;
  height: calc(100% - 10px);
  position: absolute;
  background: #a5a5a5;
  left: 0;
  top: 5px;
  display: block !important;
}

.cart-sec-ms .react-draggable:last-child .menu-box:after {
  /* display: none; */
}

.billing-list {
  padding: 0px 15px;
  background: #fff;
  margin: 10px auto;
}
.billing-list li {
  padding: 5px 0;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  justify-content: space-between;
  color: #8e8e8e;
  align-items: center;
  height: 45px;
}
.billing-list li span {
  font-weight: bold;
  color: #8d8d8d;
  text-transform: uppercase;
  font-size: 14px;
}
.billing-list li strong {
  color: #5b5c5e;
  font-size: 18px;
}
.billing-list .type-text {
  text-align: right;
  color: #b7b5b6;
  background: #fff;
  width: 80px;
  height: 35px;
  border: 1px solid #ccc;
  padding: 0 10px;
  font-weight: bold;
}

.billing-list li.total span {
  font-size: 17px;
  color: #5b5c5e;
}
.billing-list li.total strong {
  font-size: 28px;
}
.link,
a.link,
a.link:hover {
  font-size: 16px;
  font-weight: bold;
  color: #1cbec3;
  text-decoration: none;
  cursor: pointer;
}
.new_items .menu-box::before {
  content: "";
  width: 7px;
  height: calc(100% - 10px);
  position: absolute;
  background: #a5a5a5;
  left: 0;
  top: 5px;
}
.green-btn img {
  max-width: 40px;
  margin: auto;
}
.btn-sec a.green-btn,
.btn-sec a.green-btn:hover {
  text-align: center;
  color: #fff;
  text-decoration: none;
}
.varification-sec {
  background: #fff;
  z-index: 1;
  position: relative;
  /* border-radius: 20px 20px 0 0; */
  padding: 20px 0 0;
  display: flow-root;
}
.varification-sec .circle-img {
  margin: 0px auto;
}
.varification-sec .login-form h5.title {
  font-size: 26px;
}
.overlay {
  background: #f5f6f8;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
}
.cross-icon {
  background: url(../Assets/images/cross_gry.svg) no-repeat center center;
  left: auto;
  right: 15px;
}
.title {
  font-weight: bold;
}

.title-heading {
  color: #585555;
}
.num {
  width: 32px;
  height: 32px;
  background: #e8e8e8;
  text-align: center;
  border-radius: 50%;
  line-height: 1.8;
  font-weight: bold;
  margin-right: 13px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.input-text {
  border-radius: 5px;
  margin: 20px auto;
  width: 100%;
  border: 1px solid #bababc;
  height: 45px;
  font-size: 14px;
  padding: 0 10px;
}
.login-form .btn.green-btn {
  width: auto;
  font-size: 15px;
  width: 120px;
  display: table;
  margin: auto;
}

.or::before {
  content: "";
  display: inline-block;
  width: 100%;
  position: absolute;
  border-bottom: 1px solid #e8e8e8;
  left: 0;
  top: 50%;
}
.or span {
  color: #9f9f9f;
  text-transform: uppercase;
  font-weight: bold;
  background: #fff;
  border-radius: 50%;
  position: relative;
  padding: 10px;
}
.or {
  position: relative;
  width: 80%;
  text-align: center;
  margin: 20px auto;
}

.qr-sec {
  width: 65%;
  margin: 20px auto 0;
}
.qr-sec figure {
  border: 2px dashed #dddee0;
  padding: 20px;
}
.qr-sec .btn-sec {
  padding: 15px 0;
}
.qr-sec .green-btn img {
  max-width: 30px;
  margin: 0 auto 5px;
}
.qr-sec .green-btn {
  padding: 10px 0px;
  font-size: 15px;
  height: auto;
  font-weight: normal;
  color: rgba(255, 255, 255, 0.85) !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.also-like-sec {
  margin: 0;
  padding: 10px 20px;
  background: #fff;
}
.also-like-sec .menu-img {
  width: 70px;
  height: 70px;
}
.also-like-sec img {
  max-width: 80px;
  margin: 0 auto 0px;
}
.also-like-sec {
  text-align: center;
}
.sm-h5 {
  font-weight: bold;
  font-size: 16px;
  color: #595556;
  margin: 0;
}
.also-like-sec .col-6 {
  padding: 0;
  margin: 5px 0;
}

.loyality_ms .middle-sec {
  background: #fff;
  position: relative;
}
.info {
  text-align: center;
}
.info p {
  text-transform: uppercase;
  margin: 0;
  font-size: 13px;
  font-weight: bold;
  line-height: 1.3;
  color: #9f9f9f;
  flex: 0 0 100%;
}
.info h4 {
  margin: 0;
  color: #5e5e60;
  font-weight: bold;
}
.account-info .title,
.fav-info .title,
.history-info .title {
  color: #3e3e3e;
  padding: 15px 20px;
  margin: 0;
}
.info .visits {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-self: stretch;
  align-items: flex-end;
}
.border-right {
  border-right: 1px solid #e8e8e8 !important;
}
.info-sec,
.fav-sec {
  background: #fff;
  position: relative;
  margin: 15px auto 0;
}
.info-details label,
#promotions label,
#vouchers label {
  color: #9f9f9f;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
}
.tag {
  display: inline-block;
  padding: 8px 20px;
  border-radius: 30px;
  background: #ccc;
  width: 100px;
  text-align: center;
  font-weight: bold;
}
.tag.green {
  background: #9cd8d8;
  color: #105f5a;
}
.info-details p {
  font-size: 15px;
  color: #575654;
}
.info-details > div {
  margin-top: 10px;
}
.fav-sec .menu-content h4 {
  font-size: 16px;
  margin: 0;
}
.fav-sec .menu-content p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 14px;
}
.fav-sec .menu-content p.pricing {
  font-size: 17px;
}
.square-img p {
  margin: 0;
  color: #fff;
  flex: 0 0 100%;
  line-height: 1.3;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 13px;
}
.square-img {
  text-align: center;
  color: #fff;
  background: #63c6b4;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  height: 70px;
  width: 70px;
  border-radius: 5px;
  float: right;
}
.square-img h5 {
  flex: 0 0 100%;
  margin: 0 auto 5px;
  font-weight: bold;
}
.history-info h2.pricing {
  color: #696a6c;
  font-weight: bold;
  text-align: right;
}
.history-info h2.pricing small {
  font-weight: bold;
  font-size: 60%;
}
.history-info p {
  color: #595554;
  line-height: normal;
}
.history-info .naming-sec h4 {
  margin-bottom: 6px;
}
.more-btn {
  border: 0;
  display: inline-block;
  font-weight: bold;
  color: #1cbec3;
  background: url(../Assets/images/up-arrow-green.png) no-repeat 10px 10px /
    18px;
  font-size: 18px;
  float: right;
  padding: 0 0 0 40px;
}
.more-btn.open {
  background: url(../Assets/images/down-arrow-green.png) no-repeat 10px 10px /
    18px;
}
.btns .more-btn,
.btns .more-btn.open {
  background-position: 10px 20px;
}
.h5 {
}
.bill-details {
  border-top: 2px solid #e8e8e8;
  max-width: calc(100% - 0px);
  margin: auto;
}
.bill-details ul {
  margin: 0;
  padding: 10px 0 0;
}
.bill-details li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
}
.bill-details li label {
  font-weight: bold;
  color: #8e8e8e;
  text-transform: uppercase;
  font-size: 14px;
  margin: 0;
}
.bill-details h4 {
  font-size: 19px;
  font-weight: bold;
  color: #535353;
  margin: 0;
}
.bill-details li.total label,
.bill-details li.total h4 {
  color: #1cbec3;
}
.bill-details li.total {
  border-top: 2px solid #e8e8e8;
}

.history_details,
.promotion_details,
.vouchers_details {
  padding: 10px 0;
  border-bottom: 2px solid #e8e8e8;
  max-width: calc(100% - 30px);
  margin: auto;
}
.loyality_ms .link-box a,
.loyality_ms .link-box .box {
  aspect-ratio: auto 1 / 1;
  width: 100%;
}
.btns {
  justify-content: space-between;
}
.green-btn.btn-sm {
  font-size: 15px;
  text-transform: uppercase;
  height: 45px;
  width: 120px;
  border-radius: 5px;
}
#promotions .more-info p,
#vouchers .more-info p {
  font-size: 18px;
  margin: 0;
}
.promotion_details figure,
.vouchers_details figure {
  margin: 0;
}
.more-info .col-4 {
  margin-top: 15px;
}

.voucher-sec {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 0;
  max-width: 80%;
  margin: 0 auto 30px;
}
.voucher-sec li {
  margin: 0 10px;
}
.voucher-sec li a {
  padding: 10px 15px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;
  color: #9f9f9f;
}
.voucher-sec li.active a {
  border-bottom: 6px solid #1ebdc3;
}

/* For pdf */
.react-pdf__Page {
  margin-top: 10px;
}
.react-pdf__Page__textContent {
  /* border: 1px solid darkgrey;
  box-shadow: 5px 5px 5px 1px #ccc;
  border-radius: 5px; */
  display: none;
}

.react-pdf__Page__annotations.annotationLayer {
  padding: 0px;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  max-width: 600px !important;
  width: 100% !important;
}

/* For all pages */
.all-page-container {
  height: 100%;
  max-height: 500px;
  overflow: auto;
}
.pdf-next-prev-btn {
  margin: 20px auto;
  text-align: center;
}

.pdf-next-prev-btn button {
  margin: 0 5px;
  background: transparent;
  border: 1px solid rgba(0 0 0 / 20%);
  color: #585858;
  font-size: 14px;
  padding: 4px 13px;
}

/* loading-overlay */
.loading-overlay {
  display: flex;
  background: rgba(255, 255, 255, 0.7);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9998;
  align-items: center;
  justify-content: center;
}

/* page not found */
.error-template {
  padding: 40px 15px;
  text-align: center;
}
.error-actions {
  margin-top: 15px;
  margin-bottom: 15px;
}
.error-actions .btn {
  margin-right: 10px;
}

.pdf_container .container div {
  height: auto !important;
}

.pdf_container .not-found-menu {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 0 15px;
}

.pdf_container .not-found-menu h1 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.pdf_container .not-found-menu .error-details {
  line-height: 20px;
}

.pdf_container .not-found-menu figure {
  height: 60px;
  width: 60px;
  border: 2px solid #bbb;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  overflow: hidden;
  margin-bottom: 20px;
}

.error_container .container div {
  height: auto !important;
}

.error_container .not-found-menu {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 0 15px;
}

.error_container .not-found-menu {
  min-height: calc(100vh - 58px);
}

.error_container .not-found-menu h1 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.error_container .not-found-menu .error-details {
  line-height: 20px;
}

.error_container .not-found-menu figure {
  height: 60px;
  width: 60px;
  border: 2px solid #bbb;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  overflow: hidden;
  margin-bottom: 20px;
}

.scroll-active header {
  /* padding-top: 5px !important; */
  /* opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.3s all;
  transition: 0.3s all;
  height: 0; */
  /* line-height: 0; */
}

.scroll_active .middle-sec .menu-row .col a {
  height: 40px;
  background: transparent !important;
  color: #1ebdc3;
  width: max-content;
  padding: 0 7px;
}

.menu-row a,
.menu-row a:focus,
.menu-row a:hover {
  text-decoration: none;
}

.scroll_active .middle-sec .menu-row .col a {
  width: max-content;
  padding: 0 7px;
}

.scroll_active .middle-sec .menu-row .col.active a {
  background: #1ebdc3 !important;
  color: #fff;
  /* -webkit-transition: 0.05s all;
  transition: 0.05s all; */
}

.scroll_active .middle-sec .menu-row .col.active:after {
  display: none;
}

.scroll_active .middle-sec .menu-row .link-box {
  padding: 0 5px 15px;
}

.scroll_active .menu-sections {
  height: calc(100vh - 225px);
  /* max-height: calc(100vh - 250px); */
  /* -webkit-transition: 0.3s all;
  transition: 0.3s all; */
}

/* 20-aug-21 */

/* start labels */

.add-items_sec .options label {
  position: relative;
  padding-left: 37px;
  cursor: pointer;
}
.add-items_sec .options label:before {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  border-color: #1ebdc3;
}
.add-items_sec .options input:checked + label:before {
  background: transparent;
}
.add-items_sec .options input:checked + label:after {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  background-color: #1ebdc3;
  display: block;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 5px;
  border-radius: 3px;
}

/* end of labels */

.add-items_sec {
  position: relative;
}

.add-items_sec .image-sec,
.add-items_sec .image-sec .carousel-root,
.add-items_sec .image-sec .carousel-slider,
.add-items_sec .image-sec .slider-wrapper,
.add-items_sec .image-sec .slider-wrapper .slider {
  height: 100%;
}

.add-items_sec .image-sec figure {
  filter: brightness(0.5);
  background: #003e40;
}
.add-items_sec .image-sec img {
  width: 100%;
  height: 280px;
  object-fit: contain;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}
.add-items_sec .item-details {
  height: calc(100vh - 451px);
  overflow: auto;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.add-items_sec .item-details .product-details .currency {
  font-size: 14px;
}

.add-items_sec .item-details .sm-title {
  font-size: 20px;
}

.add-items_sec .item-details .product-details .description {
  text-align: left;
  margin-left: unset;
  width: 100%;
  margin-top: 6px;
}

.scroll-active .add-items_sec .item-details .product-details .description {
  margin-top: 30px;
}

.add-items_sec .image-sec .tags {
  position: absolute;
  top: 12px;
  right: 0;
  background-color: #3526a7;
  color: #fff;
  padding: 4px 13px;
  width: 200px;
  font-weight: bold;
  letter-spacing: 0.5px;
}
.add-items_sec .image-sec .tags.blue {
  background-color: #1ebdc3;
}
.add-items_sec .item-details .product-details .labels {
  text-align: right;
  margin-left: auto;
  margin-top: 15px;
}
.add-items_sec .item-details .product-details .labels ul {
  display: flex;
}
.add-items_sec .item-details .product-details .labels li {
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  cursor: default;
}
.add-items_sec .item-details .product-details .labels li:not(:last-child) {
  margin-right: 5px;
}

.add-items_sec .item-details .product-details .options {
  width: 100%;
  padding: 0;
}
.add-items_sec .item-details .product-details .options ul {
  width: 100%;
}
.add-items_sec .item-details .product-details .options li {
  width: 100%;
  display: flex;
  margin-top: 15px;
}
.add-items_sec .item-details .product-details .options li .price {
  margin-left: auto;
}
.add-items_sec .item-details .product-details .options label:before {
  border-radius: 50%;
}
.add-items_sec
  .item-details
  .product-details
  .options
  input:checked
  + label:after {
  border-radius: 50%;
}

/* cart wrapper */

.cart_wrapper .hotel_name {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.cart_wrapper .hotel_name .circle-img {
  margin-right: 20px;
}

.cart-sec-ms .other-options {
  padding: 0 0 0 10px;
  font-style: italic;
}

/* start home menus */

.menu-box .tag {
  position: absolute;
  right: 0;
  top: 8px;
  background-color: var(--dark-blue);
  border-radius: 0;
  text-align: left;
  color: #fff;
  justify-content: flex-start;
}
.menu-box .label_row {
  display: flex;
  align-items: center;
}
.menu-box .label_row ul {
  display: flex;
  margin-left: auto;
}
.menu-box .label_row ul li {
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  cursor: default;
}
.menu-box .label_row ul li:not(:last-child) {
  margin-right: 5px;
}

/* filter modal */

.filter_modal {
  border: 1px solid #b7bfbe;
  border-radius: 30px 30px 0 0;
  overflow: hidden;
  position: fixed;
  bottom: 0;
  background: #fff;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  -webkit-transition: 0.3s all;
  transition: 0.3s all;
  z-index: 99;
  width: 100%;
  max-width: 767px;
}
.filter_modal.show {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: 0.3s all;
  transition: 0.3s all;
}
.filter_modal .modal_header {
  display: flex;
  align-items: center;
  padding: 20px;
  background-color: #f2f2f2;
}
.filter_modal .modal_header .filter_icon {
  position: relative;
}
.filter_modal .modal_header .filter_icon .count {
  position: absolute;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #e45445;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  bottom: -10px;
  left: 17px;
}
.filter_modal .modal_header .filter_icon img {
  width: 24px;
}
.filter_modal .modal_header h4 {
  color: #373b3b;
  font-size: 22px;
  font-weight: bold;
  margin-left: 25px;
}
.filter_modal .modal_header .close {
  margin-left: auto;
  opacity: 1;
}
.filter_modal .modal_header .close img {
  width: 30px;
  height: auto;
}

.filter_modal .modal_body {
  padding: 20px;
}
.filter_modal .modal_body .title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}
.filter_modal .modal_body ul {
  margin-left: -5px;
  margin-right: -5px;
}
.filter_modal .modal_body ul li {
  display: inline-block;
  margin: 5px;
  border-radius: 100px;
  padding: 7px 15px;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  border: 2px solid #000;
}
.filter_modal .modal_body .apply_btn {
  margin: 0;
  display: flex;
  justify-content: center;
  background: #1ebdc3;
  padding: 10px;
  border-radius: 5px;
  align-items: center;
  color: #fff;
  cursor: pointer;
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  border: none;
  box-shadow: none;
  outline: none;
  margin-top: 20px;
}
.filter_modal .modal_body .apply_btn:disabled {
  background: #949494;
}

/* recommended section */

.recommended_section {
  padding: 10px 0px;
  overflow: hidden;
}
.recommended_section .title {
  font-size: 22px;
}
.recommended_slider {
  display: flex;
  overflow: auto;
  margin-left: -10px;
  margin-right: -10px;
}
.recommended_slider .swiper-slide {
  width: 50%;
  padding: 0 10px;
}
.recommended_slider .swiper-slide .box {
  box-shadow: none;
  border-radius: 3px;
  padding: 10px 20px;
  border-left: 3px solid #d1d7d6;
}
.recommended_slider .swiper-slide .box .title_row {
  display: flex;
}
.recommended_slider .swiper-slide .box .title_row .item_title {
  font-size: 18px;
  font-weight: bold;
  width: calc(100% - 50px);
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.recommended_slider .swiper-slide .box .title_row .item_title span {
  color: #3dc7c7;
}
.recommended_slider .swiper-slide .box .title_row .price {
  margin-left: auto;
}
.recommended_slider .swiper-slide .box .date_row {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.recommended_slider .swiper-slide .box .date_row .reorder {
  margin-left: auto;
  color: #1ebdc3;
  font-weight: bold;
}
.recommended_slider .swiper-slide .box .date_row .reorder img {
  width: 18px;
  margin-right: 5px;
}

/* guest login */

.guest_login {
  text-align: center;
  margin-bottom: 30px;
}
.guest_login a {
  color: #1ebdc3;
  font-weight: bold;
}

.menu-box .tag.blue {
  background: #2793c2;
}

/* 23-aug */

.filter_row {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
}
.filter_row .filter_icon {
  position: relative;
  cursor: pointer;
}
.filter_row .filter_icon .count {
  position: absolute;
  height: 20px;
  width: 20px;
  background-color: #e45445;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  right: -14px;
  bottom: -11px;
}
.filter_row .filter_icon img {
  width: 24px;
}
.filter_row .search-bar {
  width: calc(100% - 45px);
  margin-right: 0;
}

/* menus row */

/* .menus_row .menu-row{width:calc(100% - 45px)} */
.menus_row .filter_icon {
  /* opacity: 0;
  visibility: hidden; */
  display: none;
}
.scroll_active .menus_row {
  padding: 0 15px;
}
.scroll_active .menus_row .filter_icon {
  /* opacity: 1;
  visibility: visible;
  -webkit-transition: 0.2s all;
  transition: 0.2s all; */
  display: block;
  margin-top: -17px;
}

.scroll_active .menus_row .menu-row {
  width: calc(100% - 45px) !important;
  padding: 0;
  margin-left: 20px !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.wrapper .menu-row::-webkit-scrollbar,
.wrapper .recommended_slider::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.wrapper .menu-row,
.wrapper .recommended_slider {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* scroll add-item page */

.scroll_active .add-items_sec .image-sec img {
  height: 110px;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}
.scroll_active .add-items_sec .item-details {
  height: calc(100vh - 225px);
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.menu-sections.new_items.red,
.menu-sections.new_items.red .menu-box {
  background: rgb(237 240 240);
  color: #fff;
}

/* .menu-sections.new_items.red .menu-box h4,
.menu-sections.new_items.red .menu-box .pricing-lg {
  color: #fff;
}

.menu-sections.new_items.red .menu-content h4 {
  color: #fff;
} */

.draggable_bg {
  background-color: #fff;
  position: relative;
}

.draggable_bg .delete_box {
  position: absolute;
  left: 0;
  top: 0px;
  bottom: 0px;
  /* height: 100%; */
  width: 125px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  background-color: rgb(255 116 116);
}
.draggable_bg .delete_box img {
  width: 26px;
  filter: invert(1);
  margin-right: 7px;
}

.new_items_page .new_items {
  background-color: #fff;
}

.new_items_page .new_items .menu-box::before {
  display: none;
}

.new_items_page .menu-box:after {
  content: "";
  /* border-bottom: 1px solid #e8e8e8; */
  width: calc(100% - 32px);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: block !important;
  height: 1px;
  background-color: #e8e8e8 !important;
}

.new_items_page .draggable_bg:last-child .menu-box:after {
  display: none !important;
}

.recommended .title {
  font-weight: bold;
  font-size: 22px;
  padding: 20px 15px 15px;
  margin: 0;
}

/* welcome header */

header .welcome-sec {
  display: flex;
  margin: 0;
  align-items: center;
}
header .welcome-sec .circle-img-lg {
  height: auto;
  border: 1px solid #a5aeac;
  padding: 3px;
  background: unset;
}
header .welcome-sec .circle-img-lg .logo {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid #4f5353;
}
header .welcome-sec .circle-img-lg .logo img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

header .welcome-sec .right_col {
  width: calc(100% - 88px);
  padding-left: 15px;
}
header .welcome-sec .right_col .notify .notification {
  margin-bottom: 0 !important;
}
header .welcome-sec .right_col .naming-sec {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}
header .welcome-sec .right_col .naming-sec h3 {
  font-size: 20px;
  font-weight: 600;
}
header .welcome-sec .right_col .naming-sec .powered_text {
  margin-top: 5px;
  font-size: 13px;
  display: flex;
  align-items: center;
}

/* .resend_otp{font-size:13px;} */
.login-form .resend_row {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

.login-form .resend_row .green-btn {
  margin: 0 5px;
}

.custom_pb {
  padding-bottom: 92px;
  /* min-height: 100vh; */
}
.custom_pb .bottom-sec {
  position: fixed;
  /* position: sticky; */
  bottom: 0;
  width: 100%;
  background-color: #f5f6f7;
  box-shadow: none;
  outline: none;
  border: none;
  border: 1px solid #f5f6f7;
  max-width: 767px;
  z-index: 1;
}
.bottom-sec.disabled .green-sec {
  background-color: #86d0d2;
}

.bottom-sec.disabled .items-count {
  background: #6eb5b7;
}

/* account modal */

.accountModal,
.qrcode_modal {
  position: fixed;
  top: 100%;
  background: #f5f6f7;
  overflow: auto;
  width: 100%;
  border-radius: 30px 30px 0 0;
  bottom: 0;
  transform: translateY(100%);
  transition: 0.3s all;
  max-width: 767px;
}
.accountModal.show {
  transform: translateY(0);
  transition: 0.3s all;
  top: 62px;
  z-index: 99;
}

/* qrcode modal */

.qrcode_modal {
  top: unset;
}
.qrcode_modal.show {
  transform: translateY(0);
}
.qrcode_modal .top {
  padding: 15px 0;
}
.qrcode_modal .top h4 {
  text-align: center;
  font-size: 18px;
}
.qrcode_modal .screen {
  height: calc(100vh - 340px);
  background: antiquewhite;
  position: relative;
}
.qrcode_modal .screen .msg {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  bottom: 20px;
  background-color: #000;
  color: #fff;
  border-radius: 100px;
  padding: 0px 10px;
  width: max-content;
  font-size: 12px;
}
.qrcode_modal .bottom-sec {
  padding: 10px;
}
.qrcode_modal .bottom-sec .green-sec {
  text-align: center;
  justify-content: center;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.02rem;
  height: 50px;
}

/* star rating */
.rating_row .rating_outer {
  border: 1px solid #bababc;
  border-radius: 100px;
  width: calc(100% - 30px);
  margin: 5px auto;
  background-color: #fff;
  padding: 5px;
}
.rating_row .inner_ratings {
  display: flex !important;
  justify-content: space-evenly;
}
.rating_row .inner_ratings .fa {
  color: #d7d9d8;
}

/* custom modal */

.modal_comp {
  display: none;
  position: fixed;
  top: 0;
  margin: auto;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: 2;
}
.modal_comp.show {
  display: block;
  max-width: 767px;
}
.modal_comp.show .modal {
  display: block !important;
}
.modal_comp .modal {
  position: absolute;
  width: calc(100% - 30px);
  margin: 0 auto;
  height: auto;
  bottom: unset;
  overflow: visible;
  transition: 0.2s all;
  -webkit-transition: 0.2s all;
  border-radius: 5px;
  background-color: #fff;
}
.modal_comp.show .modal {
  top: calc(50% - 58px);
  transform: translateY(-50%);
  transition: 0.2s all;
  -webkit-transition: 0.2s all;
}
.modal_comp .modal .close {
  position: absolute;
  right: 0;
  top: -35px;
  color: #fff;
  opacity: 1;
  font-weight: normal;
  font-size: 35px;
  cursor: pointer;
}
.modal_comp .modal .close:hover {
  opacity: 1;
}
.modal_comp .modal .modal_header,
.modal_comp .modal .modal_body,
.modal_comp .modal .modal_footer {
  padding: 15px 20px;
}
.modal_comp .modal .modal_body {
  max-height: calc(100vh - 180px);
  overflow-y: auto;
  overflow-x: hidden;
}
.backdrop {
  position: fixed;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.85);
}
body #root {
  max-height: calc(100vh - 58px);
  overflow: auto;
}

body #root.modal_active {
  overflow: hidden;
}

.modal_comp .modal .modal_footer {
  border-top: 1px solid rgba(0 0 0 / 10%);
  text-align: right;
}

.modal_comp .modal_footer .btn {
  padding: 5px 15px;
  font-size: 14px;
  margin-right: 5px;
}

.foobar-header h5 {
  font-size: 22px;
  font-weight: bold;
}
.resend_row {
  display: block !important;
  height: 75px;
}
.resend_row button.btn.green-btn {
  float: right;
}
.foobar-header {
  z-index: 9;
  position: relative;
  background: #fff;
  overflow: hidden;
  opacity: 0;
}

.foobar-header.touch-top {
  padding: 25px 20px 25px 50px;
  opacity: 1;
  z-index: 10;
}

.foobar-header.touch-top .cross-btn {
  -webkit-filter: grayscale(1) invert(1);
  filter: grayscale(1) invert(1);
}
.foobar-header.touch-top .cross-btn {
  top: 23px;
}
.add-items_sec .item-details.top-scroll {
  height: calc(100vh - 264px);
  z-index: 9;
}
.foobar-header p.description {
  overflow: auto;
  height: 100%;
}

.foobar-header.touch-top .description {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: auto;
}

.edit-btn {
  background: #65c5b5;
}
.delete-btn {
  background: #d4594c;
}
.cart-sec-ms .menu-box {
  overflow: hidden;
}
.orderMenu {
  width: 100%;
  align-items: center;
  position: relative;
  z-index: 1;
  background: #fff;
  padding: 15px 0;
}
.draggable_bg .delete_box {
  background: none;
  left: auto;
  right: 0;
  width: 180px;
}
.draggable_bg .delete_box:after {
  content: "";
  width: 100%;
  right: 15px;
  bottom: 0;
  position: absolute;
  border-bottom: 1px solid #e8e8e8;
}

.edit_delete {
  position: absolute;
  right: 1px;
  top: 50%;
  transform: translate(0, -50%);
}
.edit_delete a:hover,
.edit_delete a:focus {
  color: #fff;
}

/* 08/10 */

.add-items_sec .carousel .slide img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.add-items_sec .carousel .slide div {
  height: 100%;
}

.add-items_sec .carousel .control-arrow {
  opacity: 1 !important;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #fff !important;
  border-radius: 50%;
  top: calc(50% - 30px) !important;
  transform: translateY(-50%);
}

.add-items_sec .carousel .control-arrow:before {
  opacity: 0.9;
}

.add-items_sec .carousel .control-arrow.control-prev {
  left: 10px;
}

.add-items_sec .carousel .control-arrow.control-next {
  right: 10px;
}

.add-items_sec .carousel .control-disabled.control-arrow {
  opacity: 0 !important;
}

.add-items_sec #ReactSimpleImageViewer {
  z-index: 9999;
}

.add-items_sec
  #ReactSimpleImageViewer
  .react-simple-image-viewer__modal-content {
  width: 100%;
}

.add-items_sec .react-simple-image-viewer__slide img {
  width: 100%;
  object-fit: cover;
}

.add-items_sec #ReactSimpleImageViewer .react-simple-image-viewer__next,
.add-items_sec #ReactSimpleImageViewer .react-simple-image-viewer__previous {
  opacity: 1;
  font-size: 22px;
  font-weight: 400;
  padding: 0 10px;
  display: none;
}

.add-items_sec #ReactSimpleImageViewer .react-simple-image-viewer__close {
  opacity: 1 !important;
}

#root.ios-device .menu-sections {
  height: calc(100vh - 470px);
}

#root.ios-device .scroll_active .menu-sections {
  height: calc(100vh - 164px);
}

#root.ios-device .add-items_sec .item-details {
  height: calc(100vh - 393px);
}

#root.ios-device .add-items_sec .item-details.top-scroll {
  height: calc(100vh - 207px);
}

.menu-img {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 82px;
  width: 82px;
}
.menu-img img {
  height: 100%;
  object-fit: cover;
}
.image-container-sec {
  overflow: hidden;
}

body #root.ios-device {
  max-height: unset;
}
body #root.ios-device .billing-details {
  padding-bottom: 100px;
}

body #root.ios-device .custom_pb {
  padding-bottom: 0;
  height: 100vh;
}

.add-items_sec .item-details .message_col {
  padding: 0 20px 20px;
  background-color: #fff;
  margin-bottom: 20px;
}

.add-items_sec .item-details .message_col label {
  color: #5b5b5d;
  font-weight: bold;
  font-size: 20px;
}

.add-items_sec .item-details .message_col textarea {
  height: 100px;
  border: none;
  padding: 0;
  resize: none;
}

/* 18/oct */

.cart_sidebar {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: #fff !important;
  z-index: 9;
}

.cart_sidebar header {
  background-color: rgb(245, 246, 247);
}

.outerScroll {
  max-height: calc(100vh - 148px);
  overflow: auto;
  position: relative;
}
.outerScroll.scrollup {
  top: 48px;
}
.info-sec h4.title {
  font-size: 22px;
}

.menu-sections .last-menu-items {
  min-height: calc(100vh - 220px);
}

body #root.ios-device .last-menu-items {
  min-height: calc(100vh - 160px);
}

.wrapper.scroll_active .menu-sections:last-child {
  /* padding-bottom: 80%; */
}

.cart-sec-ms .menu-box {
  min-height: 77px;
  padding: 0px 15px;
}

.orderMenu {
  min-height: 77px;
  height: 100%;
  justify-content: space-between;
}
.md-title.bold {
  font-size: 18px;
  font-weight: bold;
}
.desc-sec-top {
  text-align: center;
  padding: 15px 20px;
}

.desc-sec-top h4 {
  font-weight: bold;
  margin: 0 auto 7px;
}
