@media screen and (max-width: 1599px) {
}

@media screen and (max-width: 1365px) {
}

@media screen and (max-width: 1280px) {
}

@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 979px) {
}

@media screen and (max-width: 764px) {
}

@media (max-width: 767px) {
  .pdf_container .container {
    padding: 0;
  }

  .pdf_container canvas {
    width: 100% !important;
  }
}

@media (max-width: 575px) {
  .recommended_slider .swiper-wrapper {
    /* transform:unset!important; */
  }

  .recommended_slider .swiper-slide {
    width: 100% !important;
    flex: 0 0 80%;
    max-width: 80%;
  }
}

@media screen and (max-width: 500px) {
}

@media screen and (max-width: 374px) {
}
